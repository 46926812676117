import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { TopbarService } from './topbar.service';

@Directive({
  standalone: true,
  selector: '[appTopbarTemplate]'
})
export class TopbarTemplateDirective implements OnInit, OnDestroy {
  readonly topbarService = inject(TopbarService);
  private portal = new TemplatePortal(inject(TemplateRef), inject(ViewContainerRef));

  @Input() order = 0;

  ngOnInit(): void {
    this.topbarService.attachPortal(
      this.portal,
      this.order
    );
  }

  ngOnDestroy(): void {
    this.topbarService.detachPortals();
  }
}
