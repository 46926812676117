import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

interface PortalContainer {
  order: number;
  portal: Portal<any> | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  get portals$() {
    return this.portalsSubject.asObservable().pipe(
      map(containers => containers.map(container => container.portal)),
    );
  }

  private readonly portalsSubject = new BehaviorSubject<PortalContainer[]>([]);

  attachPortal(portal: Portal<any>, order: number) {
    this.portalsSubject.next(
      [
        ...this.portalsSubject.getValue(),
        {
          portal,
          order,
        },
      ].sort((a, b) => a.order - b.order));
  }

  detachPortals(): void {
    this.portalsSubject.next([]);
  }
}
